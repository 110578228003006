<template>
  <div
    class="main"
    v-loading="loading"
    element-loading-text="Uploading, please wait..."
  >
    <heads></heads>
    <ctdkbanners></ctdkbanners>
    <!-- <islogin /> -->
    <div class="content">
      <div class="cons">
        <div class="cons-title">Smart selection</div>
        <shuoming :smdata="smdata" :srcList="srcList"></shuoming>
        <div class="lunt-xzfs">
          <div class="lunt-xzfs-list lunt-xzfs-list-none" @click="clickzwxk">选中文刊</div>
          <div class="lunt-xzfs-list lunt-xzfs-list-active">
            Selected English publications
          </div>
          <div class="lunt-xzfs-list lunt-xzfs-list-none" @click="clickjbxk">
            Selected short editions
          </div>
        </div>
        <div class="cons-form">
          <el-form
            :model="form"
            label-width="120px"
            :rules="baseRules"
            ref="ruleFormRef"
          >
            <div class="custom-block">
              Title, abstract, and keywords are required information to recommend relevant journals.
            </div>
            <el-form-item label="title" required prop="btword">
              <el-input v-model="form.btword" placeholder="Please enter a title (5-100 characters)" @keyup.enter="onSubmit(ruleFormRef)"/>
            </el-form-item>
            <el-form-item label="abstract" required prop="zyword">
              <el-input
                v-model="form.zyword"
                type="textarea"
                placeholder="Please enter a abstract (50-3000 characters, abstracts below 150 characters may result in inaccurate recommendations)"
                 @keyup.ctrl.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <el-form-item label="keywords" required prop="kwword">
              <el-input
                v-model="form.kwword"
                placeholder="Please enter keywords separated by commas or spaces or semicolons (5-100 characters)"
                @keyup.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <div class="con-btn">
              <el-button type="primary" @click="onSubmit(ruleFormRef)"
                >Submit now</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
// 图片
let smdata = reactive([
  { url: "/img/shuoming/03-01.png", tips: "submit" },
  { url: "/img/shuoming/03-02.png", tips: "results" },
]);
let srcList = reactive([
  "/img/shuoming/03-01.png",
  "/img/shuoming/03-02.png",
]);
// loading
let loading = ref(false);
let form = reactive({
  btword: "",
  zyword: "",
  kwword: "",
  zwword: "",
  ckwx: "",
  writer: "",
  writerorg: "",
  jjkt: "",
  diqu: "",
  fbyt: "",
  sbzczy: "",
  sbzcjb: "",
  subxk: "",
});

const ruleFormRef = ref();

const baseRules = {
  btword: [{ required: true, message: "Please enter a title", trigger: "blur" }],
  zyword: [{ required: true, message: "Please enter a abstract", trigger: "blur" }],
  kwword: [{ required: true, message: "Please enter a keyword", trigger: "blur" }],
};

// 选中文刊
const clickzwxk = () => {
  router.push({
    path: "/xkzs/sub",
    query: {},
  });
};

// 简版选刊
const clickjbxk=()=>{
  router.push({
    path: "/jbtg/sbu",
    query: {},
  });
}

// 提交代码
const onSubmit = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    // 通过验证规则 true
    if (valid) {
      loading.value = true;
      post("/en/journalRecommend/SubEnPaper", form)
        .then((res) => {
          loading.value = false;
          if (res.code === 200) {
            ElMessage({
              message: "Submitted successfully!",
              duration: 1000,
              onClose: () => {
                router.push({
                  path: "/xkzsen/res",
                  query: {
                    id: res.data,
                  },
                });
              },
            });
          } else {
            ElMessage({
              message: "Submission failed:" + res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          loading.value = false;
        });
    }
  });
};
</script>

<style scoped>
:deep(.el-form-item__label) {
  background: #f5f7fa;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
}
:deep(.cons-form .el-input__inner) {
  height: 40px;
  font-size: 18px;
}
:deep(.cons-form .el-textarea__inner) {
  min-height: 100px !important;
  font-size: 18px;
}
:deep(.cons-form .el-button) {
  width: 180px;
  height: 50px;
  font-size: 18px;
}
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.cons-form {
  width: 100%;
}
.cons-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 0 0 10px 0;
}
.cons-subtitle {
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  background: #f5f7fa;
  font-size: 14px;
  line-height: 20px;
  color: #999;
  margin: auto;
  margin-bottom: 30px;
}
.custom-block {
  padding: 10px 16px;
  background-color: #ecf5ff;
  border-radius: 4px;
  border-left: 5px solid #409eff;
  margin: 20px 0 20px 0;
  font-size: 18px;
}
.con-btn {
  text-align: center;
}
.cons-title-subtitle {
  color: #999;
  font-size: 14px;
}
.cons-title-subtitle p {
  margin: 0;
  padding: 0;
  text-align: center;
}


.lunt-xzfs {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.lunt-xzfs-list {
  width: 250px;
  height: 40px;
  border: 1px solid #409eff;
  box-sizing: border-box;
  display: flex;
  margin: 0 5px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.lunt-xzfs-list-none {
  cursor: pointer;
  background: #fff;
  color: #409eff;
}
.lunt-xzfs-list-none:hover {
  background: #eef7ff;
}
.lunt-xzfs-list-active {
  background: #409eff;
  color: #fff;
  cursor: default;
}
</style>
